<template>
  <PromiseContainer :promises.sync="promises">
    <template v-slot:default>
      <v-row no-gutters justify="start" align="center">
        <v-col cols="auto">
          <h1 class="text-capitalize">
            {{ getSelectedDayFormatted }}
          </h1>
        </v-col>
      </v-row>
      <v-row no-gutters justify="start" align="center" v-if="!isOtherUserView">
        <v-btn text color="grey" class="text-none pl-0" @click="monthView">
          <v-icon color="grey" left>mdi-chevron-left</v-icon>
          Terug naar kalender
        </v-btn>
      </v-row>
      <v-row justify="start" align="center">
        <v-col cols="auto">
          <v-card-title class="pl-0 pb-0 font-weight-regular">Verwachte contractuele werktijd:</v-card-title>
          <v-card-title class="pl-0 pb-0 font-weight-regular">Geregistreerde werktijd:</v-card-title>
          <v-card-title class="pl-0 font-weight-regular">Pauze geregistreerd ({{ getExpectedBreakTime }}):</v-card-title>
        </v-col>
        <v-col cols="auto">
          <v-card-title class="pb-0 pt-0">{{ getExpectedHours }}</v-card-title>
          <v-card-title class="pb-0">{{ getRegisteredHours }}</v-card-title>
          <v-card-title class="pb-0">
            <v-icon v-if="getBreakTime" size="20" color="nav-text-active">mdi-check</v-icon>
            <v-icon v-else size="20" color="red">mdi-close</v-icon>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row justify="space-between" align="center">
        <v-col cols="auto" class="pb-1">
          <v-btn text class="text-none pl-1" @click="addDaysToCurrentDay(-1)">
            <v-icon color="primary" left>mdi-arrow-left</v-icon>
            Vorige dag
          </v-btn>
        </v-col>
        <v-col cols="auto" class="pb-1">
          <v-btn text class="text-none pr-1" @click="addDaysToCurrentDay(1)">
            Volgende dag
            <v-icon color="primary" right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="getClockings">
        <v-col cols="12" class="pt-1">
          <data-table :items="getClockings" :headers="getHeaders" @editItem="editClocking" :edit-option="showRowButton"
            @deleteItem="deleteItem" :delete-option="showRowButton" :detail-page-function="detailPageFunction">
            <template v-slot:item.startTime="{ item }">
              <span :title="item.startTimeInfo" class="d-inline-block ellipsis" :class="commentColumnMaxWidthClass">{{
    item.startTime }}</span>
            </template>
            <template v-slot:item.endTime="{ item }">
              <span :title="item.endTimeInfo" class="d-inline-block ellipsis" :class="commentColumnMaxWidthClass">{{
    item.endTime }}</span>
            </template>
            <template v-slot:item.comment="{ item }">
              <span :title="item.comment" class="d-inline-block ellipsis" :class="commentColumnMaxWidthClass">{{
    item.comment }}</span>
            </template>
            <template v-slot:item.startLocation="{ item }">
              <span v-if="item.startLocation">
                {{ item.startLocation.city }},
                <country-flag style="transform: scale(.25) translateY(8px);" :country="item.startLocation.countryCode"
                  size="small" rounded />
                {{ item.startLocation.countryCode }}
              </span>
            </template>
            <template v-slot:item.stopLocation="{ item }">
              <span v-if="item.stopLocation">
                {{ item.stopLocation.city }},
                <country-flag style="transform: scale(.25) translateY(8px);" :country="item.stopLocation.countryCode"
                  size="small" rounded />
                {{ item.stopLocation.countryCode }}
              </span>
            </template>
            <template v-slot:item.ciaoStatus="{ item }">
              <template v-if="item?.ciaoStatus">
                <v-icon v-if="item?.ciaoStatus" :color="getColorForCiaoClockingStatus(item?.ciaoStatus?.status)"
                  :title="item?.ciaoStatus?.description">{{ getIconForCiaoClockingStatus(item?.ciaoStatus?.status)
                  }}</v-icon>
              </template>
            </template>
          </data-table>
        </v-col>
      </v-row>

      <v-row align="center" justify="start">
        <v-col v-if="showHoursButton" cols="auto">
          <v-btn color="secondary" class="text-none" :to="getRegisterHoursRoute">
            <v-icon left>mdi-plus</v-icon>
            Registreer uren
          </v-btn>
        </v-col>
        <v-col v-if="showHoursButton && absenceAllowedOnDay" cols="auto">
          <v-icon color="primary" large>mdi-slash-forward</v-icon>
        </v-col>
        <v-col v-if="absenceAllowedOnDay" cols="auto">
          <v-btn color="secondary" class="text-none" :to="getRegisterAbsenceRoute">
            <v-icon left>mdi-plus</v-icon>
            Registreer afwezigheid
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </PromiseContainer>
</template>

<script>
import { addDays, format, formatDuration, isFuture, isToday } from "date-fns"
import compareAsc from "date-fns/compareAsc";
import { applyDateFormat, isAllowedParameterDay, splitHoursToDuration, getTimeZone } from "@/shared/utils/dateUtils";
import DataTable from "@/components/shared/DataTable.vue"
import routeNames from "@/router/RouteNames";
import ClockingType from "@/shared/constants/ClockingType";
import { isAdmin, isLeader } from "@/shared/utils/authenticationUtils";
import { nlBE } from "date-fns/locale"
import PromiseContainer from "../components/shared/PromiseContainer.vue";
import RouteNames from "@/router/RouteNames";
import CountryFlag from 'vue-country-flag'
import { ciaoStatuses, order } from '@/shared/constants/CiaoStatuses';
import CiaoClockingTypes from '@/shared/constants/CiaoClockingTypes';
import ModuleType from "@/shared/enums/moduleType";
import { getValueEarliestInList } from "@/shared/utils/compareUtils";
import { translateCiaoStatus, getCiaoStatusDescription, getColorForCiaoClockingStatus, getIconForCiaoClockingStatus } from "@/shared/utils/translateUtils";
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp";

export default {
  name: 'DayOverview',
  components: {
    DataTable,
    PromiseContainer,
    CountryFlag
  },
  props: {
    userId: {
      type: String,
    },
    date: {
      default: null,
      required: true,
      type: String
    },
    returnRoute: {
      type: Object,
      required: false,
      default: () => ({ name: RouteNames.MODIFY_USER_HOURS_DAY }),
    },
    editHoursRoute: {
      type: Object,
      required: false,
      default: () => ({ name: RouteNames.CALENDAR_DETAIL_USER_EDIT_HOURS }),
    },
    editAbsenceRoute: {
      type: Object,
      required: false,
      default: () => ({ name: RouteNames.CALENDAR_DETAIL_USER_EDIT_ABSENCE }),
    },
    navRoute: {
      type: Object,
      required: false,
      default: () => ({ name: RouteNames.MODIFY_USER_HOURS_DAY }),
    },
  },
  data() {
    return {
      promises: [],
      routeNames,
      ciaoClockingStatuses: [],
      ciaoStatuses,
      detailPageFunction: (clocking) => {
        if (this.isOtherUserView) {
          return {
            name: routeNames.USER_CALENDAR_DETAIL,
            params: { date: format(new Date(this.getSelectedDay), "yyyy-MM-dd"), clockingId: clocking.id, userId: this.userId }
          }
        } else {
          return {
            name: routeNames.CALENDAR_DETAIL,
            params: { date: format(new Date(this.getSelectedDay), "yyyy-MM-dd"), clockingId: clocking.id }
          }
        }
      },
      DateInClockingTimeZone: false
    }
  },
  created() {
    this.promises = [
      this.fetchDayOverview(this.getUserId),
      this.$store.dispatch('clientsModule/fetchActiveClientsForUser', this.getUserId),
      this.$store.dispatch('usersModule/fetchUserWorkSchedule', this.getUserId),
      this.$store.dispatch('locationRegistrationConfigModule/fetchLocationConfig')
    ],
      this.DateInClockingTimeZone = this.$store.getters["parameterModule/getDisplayDateInClockingTimeZone"]
  },
  methods: {
    getIconForCiaoClockingStatus,
    getColorForCiaoClockingStatus,
    getCiaoStatusFullDescription(status, id1, id2) {
      const name = translateCiaoStatus(status)
      const description = getCiaoStatusDescription(status)

      // in case the first ciao call didn't happen
      if (!id1) {
        return `${name || ''} (${id2 || ''}): ${description || ''} Enkel de OUT registratie is gebeurd.`
      }

      // in case the second ciao call didn't happen
      if (!id2) {
        return `${translateCiaoStatus(status) || ''} (${id1 || ''}): ${getCiaoStatusDescription(status) || ''} Enkel de IN registratie is gebeurd.`
      }

      return `${translateCiaoStatus(status) || ''} (${id1 || ''}, ${id2 || ''}): ${getCiaoStatusDescription(status) || ''}`
    },
    fetchCiaoStatusesForDayAndUser() {
      if (this.isCIAOEnabled) {
        return this.$store.dispatch('ciaoModule/fetchCiaoStatusesByUserIdAndDate', { userId: this.getUserId, date: this.$route.params.date })
          .then(data => this.ciaoClockingStatuses = data)
      }
      return null
    },
    applyDateFormat,
    editClocking(item) {
      if (item.clockingType === ClockingType.WORK) {
        // edit of a 'work clocking'
        if (this.userId) {
          // update clocking of another user
          this.$router.push({
            ...this.editHoursRoute,
            params: { date: this.date, clockingId: item.id, userId: this.userId }
          })
        } else {
          // update clocking of the current user
          this.$router.push({ name: routeNames.CALENDAR_DETAIL_EDIT_HOURS, params: { date: this.date, clockingId: item.id } })
        }
      } else {
        // edit of an 'absence clocking'
        if (this.userId) {
          // update the absence of another user
          this.$router.push({
            ...this.editAbsenceRoute,
            params: { date: this.date, clockingId: item.id, userId: this.userId }
          })
        } else {
          // update the absence of the current user
          this.$router.push({ name: routeNames.CALENDAR_DETAIL_EDIT_ABSENCE, params: { date: this.date, clockingId: item.id } })
        }
      }
    },
    deleteItem(item) {
      this.promises.push(
        this.$store.dispatch('clockingsModule/deleteClocking', item.id)
          .finally(() => {
            this.promises.push(this.fetchDayOverview())
            this.promises.push(this.fetchCiaoStatusesForDayAndUser())
          })
      )
    },
    async routerPushDayOverview(date) {
      if (this.isOtherUserView) {
        await this.$router.push({
          ...this.navRoute,
          params: { userId: this.getUserId, date: format(date, 'yyyy-MM-dd') }
        })
      } else {
        await this.$router.push({
          name: routeNames.DAY,
          params: { date: format(date, 'yyyy-MM-dd') }
        })
      }
    },
    /**
     * @param {int} daysToAdd: value added to or subtracted from the currently selected day - can be positive or negative
     **/
    async addDaysToCurrentDay(daysToAdd) {
      const date = addDays(this.getSelectedDay, daysToAdd)
      await this.routerPushDayOverview(date)
      this.promises.push(this.fetchDayOverview())
      this.promises.push(this.fetchCiaoStatusesForDayAndUser())
    },
    //TODO: Button to go back to selected day on Calendar
    monthView() {
      if (this.isOtherUserView) {
        this.$router.push({
          name: 'ModifyUserHours',
          params: { userId: this.getUserId, date: format(new Date(this.getSelectedDay), "yyyy-MM-dd") }
        })
      } else {
        this.$router.push({
          name: routeNames.CALENDAR,
          params: { date: format(new Date(this.getSelectedDay), "yyyy-MM-dd") }
        })
      }
    },
    fetchDayOverview() {
      return this.$store.dispatch('clockingsModule/fetchDayOverview', {
        date: this.$route.params.date,
        userId: this.getUserId
      })
    },
    // Define when a button should show per individual row
    // returns boolean, can be both for edit and delete button
    showRowButton(rowItem) {
      if (rowItem.clockingType === ClockingType.WORK) return this.workAllowedOnDay
      return this.absenceAllowedOnDay
    },
  },
  computed: {
    isCIAOEnabled() {
      return this.$store.getters["organizationModule/organizationHasModule"]([ModuleType.LOCATION_REGISTRATION]) && this.$store.getters["locationRegistrationConfigModule/getLocationConfig"]?.ciaoConfig?.ciaoEnabled === true
    },
    commentColumnMaxWidthClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
        case "xl":
          return "max-w-600p"
        case "md":
          return "max-w-300p"
        default:
          return "max-w-150p"
      }
    },
    isOtherUserView() {
      return !!this.$route.params.userId
    },
    // checks whether clockings are pure "WORK" clockings
    isWorkClockings() {
      return this.getClockings?.every(clocking => clocking.clockingType === ClockingType.WORK)
    },
    // checks whether there is at least one "WORK" clocking
    hasWorkClocking() {
      return this.getClockings?.some(clocking => clocking.clockingType === ClockingType.WORK)
    },
    hasComment() {
      return this.getClockings.some(clocking => !!clocking.comment)
    },
    getHeaders() {
      let headers = [
        {
          text: "Van",
          value: 'startTime'
        },
        {
          text: "Tot",
          value: 'endTime'
        },
      ]

      // when at least one workclocking, show client and project of that clocking if client module has been installed
      if (this.hasWorkClocking && (this.$store.getters["organizationModule/organizationHasModule"]([ModuleType.CLIENT_PROJECTMANAGEMENT]))) {
        headers.unshift(
          {
            text: "Klant",
            value: 'clientName',
            cellClass: 'notranslate'
          },
          {
            text: "Project",
            value: 'projectName',
            cellClass: 'notranslate'
          },
        )
      }

      // When at least one absence, show a header for types to discern clockings from absences
      if (!this.isWorkClockings) {
        headers.unshift(
          {
            text: "Type",
            value: 'clockingType',
          }
        )
      }

      // only show locations when at least one clocking has a location
      if (this.hasAnyLocations) {
        headers.push(
          {
            text: "Locatie",
            value: "startLocation"
          }
        )
        // only show stop location if at least one clocking has a significant difference between start and stop
        if (this.hasDifferentStartAndStopLocations) {
          headers.push(
            {
              text: "Stop locatie",
              value: "stopLocation"
            }
          )
        }


        if (this.ciaoClockingStatuses && this.ciaoClockingStatuses.length > 0) {
          headers.push(
            {
              text: "Ciao",
              value: "ciaoStatus"
            }
          )
        }
      }

      if (this.hasComment) {
        headers.push(
          {
            text: "Opmerking",
            value: "comment"
          }
        )
      }
      return headers.concat([{ value: 'actions', sortable: false, align: 'end' }])
    },
    getSelectedDay() {
      return new Date(this.$route.params.date)
    },
    getSelectedDayFormatted() {
      return format(this.getSelectedDay, 'EEEE', { locale: nlBE }) + " " + applyDateFormat(this.getSelectedDay)
    },
    hasDifferentStartAndStopLocations() {
      let clockings = this.$store.state.clockingsModule.clockings

      return clockings.some((clocking) => {

        if (clocking.clockingStartLocation && clocking.clockingStopLocation) {
          return !(clocking.clockingStartLocation?.countryCode === clocking.clockingStopLocation?.countryCode
            && clocking.clockingStartLocation?.city === clocking.clockingStopLocation?.city)
        }

        // edge case when startlocation is null, yet stoplocation is correctly filled in
        if (!clocking.clockingStartLocation && clocking.clockingStopLocation) {
          return true
        }
        return false
      })
    },
    hasAnyLocations() {
      return this.getClockings?.some(clocking => clocking?.clockingStartLocation?.city || clocking?.clockingStopLocation?.city)
    },
    workAllowedOnDay() {
      if (isToday(this.getSelectedDay))
        return true;
      if (isFuture(this.getSelectedDay))
        return false;
      return isAdmin() || isLeader() || isAllowedParameterDay(this.getSelectedDay, this.$store.getters["parameterModule/getMaxDaysClockingInThePast"]);
    },
    absenceAllowedOnDay() {
      if (isToday(this.getSelectedDay))
        return true;
      return isAdmin() || isLeader() || isAllowedParameterDay(this.getSelectedDay, this.$store.getters["parameterModule/getMaxDaysAbsenceInThePast"])
    },
    showHoursButton() {
      return this.workAllowedOnDay
    },
    getClockings() {
      let clockings = this.$store.state.clockingsModule.clockings;

      // Changes the variables that need to be updated and keeps the rest the same as they are in clockings, also check if the clocking is overlap in days or not.
      // And then changes the time accordingly either just the hours for the same day or the hour and date if there is overlap.
      let formattedClockings = clockings?.map((item) => {
        let newItem = {
          ...item,
        }

        if (item.startTime && item.endTime) {

          const startDate = DetailedTimeStamp.fromJson(item.startTime).getUTCTimeAsDate()
          const endDate = DetailedTimeStamp.fromJson(item.endTime).getUTCTimeAsDate()
          const startDateLocal = DetailedTimeStamp.fromJson(item.startTime).getLocalTimeAsDate()
          const endDateLocal = DetailedTimeStamp.fromJson(item.endTime).getLocalTimeAsDate()

          if (endDate.getDate() !== startDate.getDate()) {
            newItem.startTime = format(startDate, "HH:mm") + " " + applyDateFormat(startDate)
            newItem.endTime = format(endDate, "HH:mm") + " " + applyDateFormat(endDate)
          } else {
            newItem.startTime = format(startDate, "HH:mm")
            newItem.endTime = format(endDate, "HH:mm")
          }

          let localTime;
          const timeZone = getTimeZone()
          if (timeZone !== item.startTime.timeZone) {
            if (startDate.getDate() !== startDateLocal.getDate()) {
              localTime = format(startDateLocal, "HH:mm") + " " + applyDateFormat(startDateLocal) + " " + item.startTime?.timeZone;
            } else {
              localTime = format(startDateLocal, "HH:mm") + " " + item.startTime?.timeZone;
            }
            if (this.DateInClockingTimeZone) {
              newItem.startTimeInfo = 'In uw tijdzone: ' + newItem.startTime;
              newItem.startTime =  localTime;
            }else{
              newItem.startTimeInfo = "Origineel: " + localTime
            }
          } 

          if (timeZone !== item.endTime.timeZone) {
            if (endDate.getDate() !== endDateLocal.getDate()) {
              localTime = format(endDateLocal, "HH:mm") + " " + applyDateFormat(endDateLocal) + " " + item.endTime?.timeZone;
            } else {
              localTime = format(endDateLocal, "HH:mm") + " " + item.endTime?.timeZone
            }
            if (this.DateInClockingTimeZone) {
              newItem.endTimeInfo = 'In uw tijdzone: ' + newItem.endTime;
              newItem.endTime =  localTime;
            }else{
              newItem.endTimeInfo = "Origineel: " + localTime
            }
          }
        }

        if (item?.clockingStartLocation?.countryCode && item?.clockingStartLocation?.city) {
          newItem.startLocation = {
            city: item?.clockingStartLocation?.city,
            countryCode: item?.clockingStartLocation?.countryCode
          }
        }
        if (item?.clockingStopLocation?.countryCode && item?.clockingStopLocation?.city) {
          newItem.stopLocation = {
            city: item?.clockingStopLocation?.city,
            countryCode: item?.clockingStopLocation?.countryCode
          }
        }

        if (this.isCIAOEnabled) {
          const ciaoClockingStatusIn = this.ciaoClockingStatuses?.find(ciaoClockingStatus => item.id == ciaoClockingStatus?.clockingId && ciaoClockingStatus?.ciaoClockingType == CiaoClockingTypes.IN)
          const ciaoClockingStatusOut = this.ciaoClockingStatuses?.find(ciaoClockingStatus => item.id == ciaoClockingStatus?.clockingId && ciaoClockingStatus?.ciaoClockingType == CiaoClockingTypes.OUT)

          let status
          let description
          let INdescription
          let OUTdescription

          if (ciaoClockingStatusIn && !ciaoClockingStatusOut) {
            status = getValueEarliestInList(order, ciaoClockingStatusIn?.ciaoStatus, ciaoStatuses.OUT_OF_SYNC)
            description = `${translateCiaoStatus(status) || ''} (${ciaoClockingStatusIn?.ciaoRegistrationId || '?'}):`
            INdescription = getCiaoStatusDescription(ciaoClockingStatusIn?.ciaoStatus, CiaoClockingTypes.IN)
            OUTdescription = "De OUT registratie is niet gebeurd."
          } else if (ciaoClockingStatusOut && !ciaoClockingStatusIn) {
            status = getValueEarliestInList(order, ciaoClockingStatusIn?.ciaoStatus, ciaoStatuses.OUT_OF_SYNC)
            description = `${translateCiaoStatus(status) || ''} (${ciaoClockingStatusOut?.ciaoRegistrationId || '?'}):`
            INdescription = "De IN registratie is niet gebeurd."
            OUTdescription = getCiaoStatusDescription(ciaoClockingStatusOut?.ciaoStatus, CiaoClockingTypes.OUT)
          } else if (ciaoClockingStatusIn && ciaoClockingStatusOut) {
            const hasSameStatus = ciaoClockingStatusIn?.ciaoStatus == ciaoClockingStatusOut?.ciaoStatus
            status = getValueEarliestInList(order, ciaoClockingStatusIn?.ciaoStatus, ciaoClockingStatusOut?.ciaoStatus)
            description = `${translateCiaoStatus(status) || ''} (${ciaoClockingStatusIn?.ciaoRegistrationId || '?'}, ${ciaoClockingStatusOut?.ciaoRegistrationId || '?'}):`
            INdescription = getCiaoStatusDescription(ciaoClockingStatusIn?.ciaoStatus, hasSameStatus ? null : CiaoClockingTypes.IN)
            if (!hasSameStatus) {
              OUTdescription = getCiaoStatusDescription(ciaoClockingStatusOut?.ciaoStatus, CiaoClockingTypes.OUT)
            }
          }

          if (status) {
            newItem.ciaoStatus = {
              status: status,
              description: `${description || ''} ${INdescription || ''} ${OUTdescription || ''}`
            }
          }

        }

        return newItem
      })

      if (formattedClockings && formattedClockings.length) {
        return formattedClockings.sort((a, b) => compareAsc(a.startDate, b.startDate))
      } else {
        return null
      }
    },
    getExpectedHours() {
      if (this.$store.state.usersModule.userWorkSchedule?.hoursPerDay) {
        const expected = this.$store.state.usersModule.userWorkSchedule.hoursPerDay[format(this.getSelectedDay, "EEEE").toLocaleUpperCase()]
        if (expected && expected > 0) {
          const splitExpected = splitHoursToDuration(expected)
          return formatDuration(splitExpected, { locale: nlBE })
        }
      }
      return "-"
    },
    getExpectedBreakTime() {
      const expectedBreak = this.$store.state.usersModule.userWorkSchedule.breakLength
      if (expectedBreak > 0) {
        return formatDuration(splitHoursToDuration(expectedBreak), { locale: nlBE })
      } else {
        return "-"
      }
    },
    getBreakTime() {
      return this.$store.state.clockingsModule.breakTime
    },
    getRegisteredHours() {
      const registered = this.$store.state.clockingsModule.registeredHours

      if (registered > 0) {
        const splitRegistered = splitHoursToDuration(registered)
        return formatDuration(splitRegistered, { locale: nlBE })
      } else {
        return "-"
      }
    },
    getUserId() {
      return this.userId || this.$store.state.authModule.user.id
    },
    getRegisterHoursRoute() {
      if (this.userId) {
        return { name: this.editHoursRoute.name, params: { date: this.date, userId: this.userId } }
      } else {
        return { name: routeNames.CALENDAR_DETAIL_EDIT_HOURS, params: { date: this.date } }
      }
    },
    getRegisterAbsenceRoute() {
      if (this.userId) {
        return { name: this.editAbsenceRoute.name, params: { date: this.date, userId: this.userId } }
      } else {
        return { name: routeNames.CALENDAR_DETAIL_EDIT_ABSENCE, params: { date: this.date } }
      }
    },
  },
  watch: {
    isCIAOEnabled: {
      handler() {
        this.fetchCiaoStatusesForDayAndUser()
      },
      immediate: true
    }
  }
}
</script>
